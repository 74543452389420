<template>
  <no-sidebar-layout>
    <v-container class="px-2 pt-16 pb-6">
      <v-row class="my-0" justify="center">
        <v-col cols="12" class="py-0">
          <v-img class="mt-9 mb-9" :max-height="188" contain src="@/assets/phone-link.svg" />
          <p class="mt-4 body-1 font-weight-bold text-center" v-html="$t('matchEditorDynamicLink.message')" />
        </v-col>
      </v-row>
    </v-container>
  </no-sidebar-layout>
</template>
<script>
export default {
  name: 'MatchEditorDynamicLink',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
  },
}
</script>
